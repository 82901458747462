import React from "react";

interface Props {
  loading: boolean;
  variant?: string;
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
}
const Button = ({
  loading,
  onClick,
  variant = "primary",
  children,
  className,
}: Props) => {
  return (
    <button
      className={`btn btn-${variant} ${className}`}
      disabled={loading}
      onClick={onClick}
    >
      <div className="d-flex align-items-center gap-2">
        {loading && (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>{" "}
          </div>
        )}
        {children}
      </div>
    </button>
  );
};

export default Button;
