import axios from "axios";

export async function callAPI(
  accessToken: string,
  endpoint: string,
  body?: any,
  method?: string
) {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: method || "GET",
    headers: headers,
    data: body,
  };
  const response = await axios(
    `${process.env.REACT_APP_API_URI}/${endpoint}`,
    options
  );

  return response.data;
}
