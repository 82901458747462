import { Navigate, useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>SimanCloud&reg; SupportApps</h1>
      <h3>Bienvenido</h3>

      <div></div>
    </div>
  );
};

export default Home;
