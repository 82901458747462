import { AxiosError } from "axios";
import { useState } from "react";
import Button from "../components/Button";
import { callAPI } from "../utils/apiHelper";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { AiFillDelete } from "react-icons/ai";
import React from "react";

type SessionsResponse = {
  CpuUsage: number;
  MemoryUsage: number;
  ServerName: string;
  Sessions: Array<Session>;
  DiskRemaining: number;
};

type Session = {
  State: string;
  UserName: string;
};

const UsersOnServer = () => {
  const [data, setData] = useState<SessionsResponse | null>();
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { instance, accounts } = useMsal();

  const getAccessToken = async () =>
    await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

  const handleSearch = async () => {
    setError("");

    if (!userName) {
      setError("Escriba el nombre del usuario a consultar");
      return;
    }

    try {
      setLoading(true);

      const tokenResponse = await getAccessToken();
      const url = `RdServerByUser?user=${userName}`;
      const response = await callAPI(tokenResponse.accessToken, url);
      setData(response);
    } catch (error: unknown) {
      console.log("ERR", error);
      const ex = error as AxiosError;
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 403)
      )
        setError((ex.response.data as any).Details);
      else setError(ex.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSession = async (userName: string) => {
    if (!data) return;

    if (!window.confirm(`¿Está seguro de cerrar la sesión de ${userName}?`))
      return;

    setError("");

    try {
      setLoading(true);

      const tokenResponse = await getAccessToken();

      const url = "RemoveSession";
      await callAPI(
        tokenResponse.accessToken,
        url,
        { userName: userName, serverName: data.ServerName },
        "DELETE"
      );
      await loadUsersByServer();
    } catch (error: unknown) {
      console.log("ERR", error);
      const ex = error as AxiosError;
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 403)
      )
        setError((ex.response.data as any).Details);
      else setError(ex.message);
    } finally {
      setLoading(false);
    }
  };

  const loadUsersByServer = async () => {
    setError("");

    if (!data?.ServerName) {
      return;
    }

    try {
      setLoading(true);

      const tokenResponse = await getAccessToken();
      const url = `RdServer?server=${data.ServerName}`;
      const response = await callAPI(tokenResponse.accessToken, url);
      setData(response);

      console.log("RESPONSE", response);
    } catch (error: unknown) {
      console.log("ERR", error);
      const ex = error as AxiosError;
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 403)
      )
        setError((ex.response.data as any).Details);
      else setError(ex.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div>
        <div className="border-bottom mb-3">
          <h3>Usuarios en el servidor</h3>
          <p className="text-muted">
            Muestra los usuarios firmados en el mismo servidor que el usuario
            dado.
          </p>
        </div>

        <div className="mt-4">
          <div className="mb-3">
            <label htmlFor="userName" className="form-label">
              Nombre de usuario
            </label>
            <div className="d-flex flex-row justify-content-between gap-2">
              <input
                type="text"
                className="form-control"
                id="userName"
                value={userName}
                disabled={loading}
                onChange={(e) => setUserName(e.currentTarget.value)}
                placeholder="SUSXXX-Nombre"
              />
              <Button loading={loading} onClick={handleSearch}>
                Consultar
              </Button>
            </div>
          </div>
        </div>

        {data && data.Sessions && (
          <div className="border-top mt-4 py-3">
            <h5>
              {data.Sessions.length} usuarios en el servidor {data.ServerName}:
            </h5>
            <div>
              {data.Sessions.map((x: any, i: number) => (
                <div key={i} className="my-3 d-flex align-items-center">
                  <Button
                    variant="outline-danger"
                    loading={loading}
                    className="btn-sm me-2"
                    onClick={() => handleCloseSession(x.UserName)}
                  >
                    <AiFillDelete />
                  </Button>
                  {x.UserName}{" "}
                </div>
              ))}
            </div>
            <div>
              <ul>
                <li>
                  Total de usuarios: <strong>{data.Sessions.length}</strong>
                </li>
                <li>
                  CPU <strong>{data.CpuUsage.toFixed(2)}%</strong>
                </li>
                <li>
                  Memoria <strong>{Math.round(data.MemoryUsage)}%</strong>
                </li>
                <li>
                  Espacio libre en disco:{" "}
                  <strong>
                    {(data.DiskRemaining / 1024 ** 3).toFixed(2)} GB
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        )}

        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

export default UsersOnServer;
