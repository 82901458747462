import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Button variant="primary" onClick={() => handleLogout("redirect")}>
      Cerrar sesión
    </Button>
  );
};

export default SignOutButton;
