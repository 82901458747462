import axios from "axios";
import React, { useEffect } from "react";

const convert = require("xml-js");
interface VersionesComboProps {
  onVersionChange: (selectedVersion: string) => void;
}

interface BlobPrefix {
  Name: {
    _text: string;
  };
}

interface Blobs {
  BlobPrefix: BlobPrefix[];
}

interface EnumerationResults {
  _attributes: {
    ContainerName: string;
  };
  Delimiter: {
    _text: string;
  };
  Blobs: Blobs;
}

interface MyJsonData {
  _declaration: {
    _attributes: {
      version: string;
      encoding: string;
    };
  };
  EnumerationResults: EnumerationResults;
}

const VersionesCombo: React.FC<VersionesComboProps> = ({ onVersionChange }) => {
  const [versiones, setVersiones] = React.useState<MyJsonData>();
  const [selectedVersion, setSelectedVersion] = React.useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      const url =
        "https://siman.blob.core.windows.net/sg8?restype=container&comp=list&delimiter=/";
      const options = { headers: { Accept: "application/xml" } };
      const response = await axios.get(url, options);

      const xml = response.data;
      const json = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 2 })
      );

      (json as MyJsonData).EnumerationResults.Blobs.BlobPrefix.map((blob) => {
        blob.Name._text = blob.Name._text.slice(0, -1);
      });
      setVersiones(json);
    };
    fetchData();
  }, []);

  return (
    <select
      className="form-select"
      aria-label="Default select example"
      value={selectedVersion}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onVersionChange(e.target.value)
      }
    >
      <option selected>Seleccione la versión</option>
      {versiones &&
        versiones.EnumerationResults.Blobs.BlobPrefix.map((blob, index) => (
          <option key={index} value={blob.Name._text}>
            {blob.Name._text}
          </option>
        ))}
    </select>
  );
};

export default VersionesCombo;
