import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UsersOnServer from "./pages/UsersOnServer";
import { AiOutlineCloud } from "react-icons/ai";
import ScaleRDS from "./pages/ScaleRDS";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import SignOutButton from "./components/Auth/SignOutButton";
import SignInButton from "./components/Auth/SignInButton";
import { loginRequest } from "./authConfig";
import { callAPI } from "./utils/apiHelper";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { Container, Nav, Navbar } from "react-bootstrap";
import Updates from "./pages/Updates";
function App() {
  const router = createBrowserRouter([
    { path: "/users-on-server", element: <UsersOnServer /> },
    { path: "/scale-rds", element: <ScaleRDS /> },
    { path: "/updates", element: <Updates /> },
    { path: "/", element: <Home /> },
  ]);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [data, setData] = useState();

  const handleFetch = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callAPI(response.accessToken, "Test-Auth").then((response) => {
          setData(response);
          console.log("RESPONSE", response);
        });
      });
  };

  const handleFetchInvalid = () => {
    const options = {
      method: "GET",
    };

    return fetch(`${process.env.REACT_APP_API_URI}/Test-Auth`, options)
      .then((response) => console.log("RESPONSE", response))
      .catch((error) => console.log("ERROR", error));
  };

  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow-sm">
        <div className="container">
          <div className="container-fluid">
            <div className="navbar-brand">
              <div className="d-flex align-items-center gap-2">
                <a className="navbar-brand" href="/">
                  <AiOutlineCloud /> <span>SimanCloud</span>
                </a>
                {/*   {isAuthenticated ? <SignOutButton /> : <SignInButton />} */}
              </div>
            </div>
            <ul className="navbar-nav flex-grow-1">
              <li className="nav-item">
                <a className="nav-link text-light" href="/scale-rds">
                  Escalamiento RDS
                </a>
              </li>
              <li className="nav-item">
                <a href="/users-on-server" className="nav-link text-light">
                  Usuarios en servidor
                </a>
              </li>
              <li className="nav-item">
                <a href="/updates" className="nav-link text-light">
                  Actualizaciones
                </a>
              </li>
            </ul>

            <div className="d-flex align-items-end">
              {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </div>
          </div>
        </div>
      </nav>

      {/*  <header className="navbar navbar-dark bg-dark shadow-sm">
        <div className="container">
          <div className="navbar-brand">
            <div className="d-flex align-items-center gap-2">
              <AiOutlineCloud /> <span>SimanCloud</span>
              {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </div>
          </div>
        </div>
      </header> */}
      <div className="container p-4">
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <RouterProvider router={router} />
        </AuthenticatedTemplate>
      </div>
    </div>
  );
}

export default App;
