import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "react-bootstrap";

const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((result) => console.log(result))
        .catch((e) => console.log(e));
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => console.log(e));
    }
  };

  return (
    <Button variant="primary" onClick={() => handleLogin("popup")}>
      Iniciar sesión
    </Button>
  );
};

export default SignInButton;
