export const today = () => getJustDate(new Date());

export const getJustDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

export const getDateFromInput = (value: string) => {
  const parseYear = parseInt(value.substring(0, 4));
  const parseMonth = parseInt(value.substring(5, 7)) - 1;
  const parseDay = parseInt(value.substring(8, 10));
  const parsedDate = new Date(parseYear, parseMonth, parseDay);

  return getJustDate(parsedDate);
};
