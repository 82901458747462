import React, { useState } from "react";
import Button from "../components/Button";
import axios, { AxiosError } from "axios";
import VersionesCombo from "../components/VersionesCombo";

const Updates = () => {
  interface BlobItem {
    Name: {
      _text: string;
    };
    Url: {
      _text: string;
    };
    Properties: {
      "Last-Modified": {
        _text: string;
      };
      Etag: {
        _text: string;
      };
      "Content-Length": {
        _text: string;
      };
      "Content-Type": {
        _text: string;
      };
      "Content-Encoding": any;
      "Content-Language": any;
      "Content-MD5": any;
      "Cache-Control": any;
      BlobType: {
        _text: string;
      };
      LeaseStatus: {
        _text: string;
      };
    };
  }

  interface Blobs {
    Blob: BlobItem[];
  }

  interface EnumerationResults {
    _attributes: {
      ContainerName: string;
    };
    Prefix: {
      _text: string;
    };
    Blobs: Blobs;
  }

  interface MyJsonData {
    _declaration: {
      _attributes: {
        version: string;
        encoding: string;
      };
    };
    EnumerationResults: EnumerationResults;
  }

  const convert = require("xml-js");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [data, setData] = useState<MyJsonData | null>(null);

  const [version, setVersion] = useState<string>("");

  const loadUpdates = async (version: string) => {
    setError("");

    if (!version) {
      setError("Escriba la versión a consultar");
      return;
    }

    try {
      setLoading(true);

      const url = `https://siman.blob.core.windows.net/sg8?restype=container&comp=list&prefix=${version}`;

      const options = { headers: { Accept: "application/xml" } };
      const response = await axios.get(url, options);

      const xml = response.data;

      const json = JSON.parse(
        convert.xml2json(xml, { compact: true, spaces: 2 })
      );

      setData(json);
    } catch (error: unknown) {
      console.log("ERR", error);
      const ex = error as AxiosError;
      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 403)
      )
        setError((ex.response.data as any).Details);
      else setError(ex.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="row">
      <div>
        <div className="border-bottom mb-3">
          <h3>Actualizaciones</h3>
          <p className="text-muted">
            Esta sección permite descargar las actualizaciones personalizadas.
          </p>
        </div>

        <div className="mt-4">
          <div className="mb-3">
            <label htmlFor="version" className="form-label">
              Versión
            </label>
            <div className="d-flex flex-row justify-content-between gap-2">
              <VersionesCombo
                onVersionChange={(version) => loadUpdates(version)}
              />
            </div>
          </div>
        </div>

        <div>
          {data &&
            data.EnumerationResults.Blobs.Blob.map((blob, index) => (
              <div key={index}>
                <p className="h6">
                  <a href={blob.Url._text}>{blob.Name._text}</a>
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Updates;
