import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useEffect } from "react";

const Login = () => {
  const { instance } = useMsal();
  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((result) => console.log(result))
        .catch((e) => console.log(e));
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    handleLogin("redirect");
  }, []);

  return <></>;
};

export default Login;
