import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Button from "../components/Button";
import { getDateFromInput, getJustDate, today } from "../utils/datesHelper";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { callAPI } from "../utils/apiHelper";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

//https://devexpress.github.io/devextreme-reactive/

type Data = {
  Average: string;
  Open: string;
  Max: string;
  Time: string;
  Min: string;
  RowKey: string;
  Stage: string;
  RDSPool: string;
  PartitionKey: string;
  Running: string;
  Sessions: string;
  Connected: string;
  Start: string;
  [key: string]: string | number; // Index signature
};

const ScaleRDS = () => {
  const [data, setData] = useState<Data[] | null>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { instance, accounts } = useMsal();

  const [filters, setFilters] = useState<Partial<Data>>();

  useEffect(() => {
    setSelectedDate(today());
  }, []);

  const loadData = async () => {
    setError("");
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setLoading(true);
      const fecha = getJustDate(selectedDate).toISOString();
      const url = `logs/ScaleRds/${fecha}`;
      const response = await callAPI(tokenResponse.accessToken, url);

      setData(response);
    } catch (error: unknown) {
      const ex = error as AxiosError;
      if (ex.response && ex.response.status === 404)
        setError((ex.response.data as any).Details);
      else setError(ex.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (column: keyof Data, value: string | number) => {
    const f = {
      ...filters,
      [column]: value,
    };
    setFilters(f);
  };

  const filteredData = () => {
    if (!data) return [];
    if (!filters) return data;

    const f = data.filter((item) => {
      return Object.entries(filters).every(([column, value]) => {
        if (value === undefined || value === "") {
          return true; // Skip filtering for empty values
        }
        if (typeof item[column as keyof Data] === "string") {
          return String(item[column as keyof Data])
            .toLowerCase()
            .startsWith(String(value).toLowerCase());
        }
        return String(item[column as keyof Data])
          .toLowerCase()
          .startsWith(String(value).toLowerCase());
      });
    });

    return f;
  };
  return (
    <div>
      <div className="border-bottom mb-3">
        <h3>Escalamiento RDS</h3>
        <p className="text-muted">
          Muestra los servidores RDS como fueron escalados o des-escalados en el
          tiempo
        </p>
      </div>
      <div className="form-group d-flex gap-2 align-items-center">
        <label>Fecha</label>
        <input
          className="form-control"
          type="date"
          value={selectedDate ? selectedDate.toISOString().split("T")[0] : ""}
          onChange={(e) => setSelectedDate(getDateFromInput(e.target.value))}
        />
        <Button loading={loading} onClick={loadData}>
          Cargar
        </Button>

        <hr />
      </div>

      <div className="mt-4 card">
        <div className="table-responsive" style={{ maxHeight: "70vh" }}>
          <table className="table table-striped ">
            <thead>
              <tr className="table-header">
                <th scope="col">RDSPool</th>
                <th scope="col">DateTime</th>
                <th scope="col">Stage</th>
                <th scope="col">Running</th>
                <th scope="col">Open</th>
                <th scope="col">Connected</th>
                <th scope="col">Sessions</th>
                <th scope="col">Average</th>
                <th scope="col">Max</th>
                <th scope="col">Min</th>
                <th scope="col">Drain</th>
                <th scope="col">Start</th>
                <th scope="col">Stop</th>
              </tr>
            </thead>
            <tbody>
              {/*  <tr>
                <td>
                  <input
                    type="text"
                    value={filters?.RDSPool}
                    onChange={(e) =>
                      handleFilterChange("RDSPool", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters?.RowKey}
                    onChange={(e) =>
                      handleFilterChange("RowKey", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters?.Stage}
                    onChange={(e) =>
                      handleFilterChange("Stage", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters?.Time}
                    onChange={(e) =>
                      handleFilterChange("Time", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters?.Min}
                    onChange={(e) =>
                      handleFilterChange("Min", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters?.RowKey}
                    onChange={(e) =>
                      handleFilterChange("RowKey", e.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr> */}
              {filteredData()?.map((d, i) => (
                <tr key={i}>
                  <td>{d.RDSPool}</td>
                  <td>{d.RowKey}</td>
                  <td>{d.Stage}</td>
                  <td>{d.Running}</td>
                  <td>{d.Open}</td>
                  <td>{d.Connected}</td>
                  <td>{d.Sessions}</td>
                  <td>{d.Average}</td>
                  <td>{d.Max}</td>
                  <td>{d.Min}</td>
                  <td>{d.Drain}</td>
                  <td>{d.Start}</td>
                  <td>{d.Stop}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ScaleRDS;
